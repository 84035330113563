<template>
  <button
    @click="$emit('click', $event)"
    :disabled="isLoading"
    type="button"
    class="text-nowrap"
  >
    <span
      v-if="isLoading"
      class="spinner-border spinner-border-sm"
      role="status"
      aria-hidden="true"
    ></span>
    <fa-icon v-else :icon="[iconSource, icon]"></fa-icon>
    {{ isLoading ? textBusy : text }}
  </button>
</template>

<script>
export default {
  name: 'IconButton',
  props: {
    icon: {
      type: String,
      required: true,
    },
    iconSource: {
      type: String,
      default: 'fas',
    },
    text: {
      type: String,
      default: '',
    },
    textBusy: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
